<template>
  <div>
    <div class="detailContent" :data="detailData">
      <!-- 应用信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.Sensitivebehavior.AppInfo')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('public.ApplicationName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.name }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.ApplicationVersion')+'：'}}</span>
            <span class="detaildesc">{{ detailData.version }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.AppPackageName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.packageName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">{{$t('SecurityMonitor.Sensitivebehavior.AppMD5')+'：'}}</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.appMd5 }}</span>
          </div>
        </div>
      </div>
      <!-- 基本信息 -->
      <el-descriptions :title="$t('SecurityMonitor.Sensitivebehavior.BasicInfo')" :column="2">
        <el-descriptions-item :label="$t('public.DeviceName')">{{ detailData.deviceName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.Name')">{{ detailData.username }}</el-descriptions-item>
        <!-- <el-descriptions-item label="设备厂商">{{ detailData.operator }}</el-descriptions-item>
        <el-descriptions-item label="合规状态">{{ detailData.isDanger }}</el-descriptions-item> -->
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.UserDepartment')">{{  detailData.groupfullname.substr(groupFullNameShowByIndex) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.SystemVersion')">{{ detailData.osVersion }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.DeviceIP')">{{ detailData.ip }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.Username')">{{ detailData.loginname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.EquipmentModel')">{{ detailData.model }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.InternetMethod')">{{ $filter.surfInterType(detailData.netType) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.EquipmentIMEI')">{{ detailData.imei }}</el-descriptions-item>
      </el-descriptions>

      <!-- <p class="detailTitle">基本信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">设备名称：</span>
            <span class="detaildesc">{{ detailData.deviceName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">姓名：</span>
            <span class="detaildesc">{{ detailData.username }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">合规状态：</span>
            <span class="detaildesc"></span>
          </div>
          <div class="group">
            <span class="detailLabel">系统版本：</span>
            <span class="detaildesc">{{ detailData.osVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备IP：</span>
            <span class="detaildesc">{{ detailData.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">用户名：</span>
            <span class="detaildesc">{{ detailData.loginname }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">用户部门：</span>
            <span class="detaildesc">{{ detailData.groupfullname.substr(groupFullNameShowByIndex) }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备越狱：</span>
            <span class="detaildesc"></span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left; ">设备IMEI：</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.imei }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left; ">上网方式：</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.netType }}</span>
          </div>
        </div>
      </div> -->
      <!-- 位置信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.Sensitivebehavior.PositionInfor')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.Country')+'：'}}</span>
            <span class="detaildesc">{{ detailData.country }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.City')+'：'}}</span>
            <span class="detaildesc">{{ detailData.city }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.Province')+'：'}}</span>
            <span class="detaildesc">{{ detailData.province }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.LatitudeLongitude')+'：'}}</span>
            <span class="detaildesc" v-if="detailData.longitude == ''"></span>
            <span class="detaildesc" v-else>{{ detailData.longitude }},{{ detailData.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.Address')+'：'}}</span>
          <span class="detaildesc">{{ detailData.location }}</span>
        </div>
      </div>
      <!-- 风险信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.Sensitivebehavior.RiskInfo')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.SafetyEvent.EventName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.eventName }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.GenerationTime')+'：'}}</span>
            <span class="detaildesc">{{
              detailData.createtime
            }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('public.Behaviorlevel')+'：'}}</span>
            <span class="detaildesc" v-if="detailData.level == '3'" style="color: #d9001b; cursor: auto">{{$t('SecurityMonitor.Sensitivebehavior.High')}}</span>
            <span class="detaildesc" v-if="detailData.level == '2'" style="color: #f59a23; cursor: auto">{{$t('SecurityMonitor.Sensitivebehavior.Middle')}}</span>
            <span class="detaildesc" v-if="detailData.level == '1'" style="color: #00cdcd; cursor: auto">{{$t('SecurityMonitor.Sensitivebehavior.Low')}}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.ReportingTime')+'：'}}</span>
            <span class="detaildesc">{{
              detailData.time
            }}</span>
          </div>
        </div>
      </div>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel" style="display: block; float: left; width: 27%">{{$t('SecurityMonitor.Sensitivebehavior.ResponseAction')+'：'}}</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.rule
            }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.SafetyEvent.BanTime')+'：'}}</span>
            <span class="detaildesc">{{ detailData.updateTime | dateformat }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="boxBot"> -->
      <!-- <div class="group"> -->
      <!-- <span -->
      <!-- class="detailLabel" -->
      <!-- style="display: block; float: left; width: 13%" -->
      <!-- >响应动作：</span -->
      <!-- > -->
      <!-- <span -->
      <!-- class="detaildesc" -->
      <!-- style="display: block; float: left; text-align: justify" -->
      <!-- >{{ detailData.rule }}</span -->
      <!-- > -->
      <!-- </div> -->
      <!-- </div> -->
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel" style="display: block; float: left; width: 27%">{{$t('SecurityMonitor.SafetyEvent.EventRule')+'：'}}</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.eventContent }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.SafetyEvent.BlockingMode')+'：'}}</span>
            <span class="detaildesc">{{ detailData.ban }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%">
          <div class="detailLabel" style="
                float: left;
                height: 24px;
                line-height: 24px;
                text-align: left;
                pading-right: 5px;
                width:13%;
              ">
            <span>{{$t('SecurityMonitor.Sensitivebehavior.JudgmentSteps')+'：'}}</span>
          </div>
          <div style="
                float: left;
                width: 78%;
                height: inherit;
                text-align: left;
                padding-right: 5px;
              " class="detaildesc">
            <p v-for="(e, i) in detailData.decideStr" :key="i" style="
                  width: 100%;
                  display: inline-block;
                  overflow: hidden;
                  color: #606266;
                  margin-left: 0;
                  line-height: 24px;
                  word-wrap: break-word;
                  word-break: break-all;
                  color:#606266
                ">
              {{ i + 1 }}、{{ e }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detailData: {
      type: Object,
      default: {}
    }
  },

  data() {
    return {
      groupFullNameShowByIndex: '',
    }
  },

  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
.detailContent {
  width: 100%;

  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }

  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;

    .boxLeft {
      width: 50%;
      margin-left: 68px;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 44%;
          text-align: left;
        }

        .detaildesc {
          width: 56%;
        }
      }
    }

    .boxRight {
      width: 50%;
      line-height: 24px;

      .group {
        margin-top: 8px;

        .detailLabel {
          width: 23%;
          text-align: left;
        }

        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }

  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;

    .group {
      margin-top: 8px;

      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }

      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}
::v-deep .el-descriptions__title {
  color: #606266;
  font-size: 14px;
}
::v-deep .el-descriptions__body {
  color: #606266;
  font-size: 12px;
  background-color: #fff;
  padding-left: 70px;
}
</style>
