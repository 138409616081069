<template>
  <div class="SensitiveMonitor">
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <!-- 起始日期 -->
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.startTime"
          type="date"
          :placeholder="$t('SecurityMonitor.WarningInfo.Placeholder')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker
          :clearable="false"
          size="small"
          style="width: 100%"
          v-model="formInline.endTime"
          type="date"
          :placeholder="$t('SecurityMonitor.WarningInfo.Placeholder')"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <!-- 事件名称 -->
        <el-select
          style="width: 100%"
          v-model="formInline.reason"
          :placeholder="$t('SecurityMonitor.SafetyEvent.EventName')"
          size="small"
          class="location"
        >
          <el-option
            v-for="item in reasonOptions"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <!-- 行为级别 -->
        <el-select
          style="width: 100%"
          v-model="formInline.level"
          :placeholder="$t('public.Behaviorlevel')"
          size="small"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="
              $t(`SecurityMonitor.SafetyEvent.levelOptions.${item.label}`)
            "
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <!-- 位置 -->
        <el-cascader
          size="small"
          style="width: 100%"
          :options="cityOptions"
          collapse-tags
          :props="props"
          v-model="formInline.location"
          :placeholder="$t('public.Position')"
          filterable
          class="placeInput"
        ></el-cascader>
      </el-col>
      <select-input
        :options="devNameOptions"
        @selectSearchChange="selectSearchChange"
        @searchList="onSubmit"
      ></select-input>
      <!-- <el-col :span="3">
          <el-select style="width: 100%" v-model="formInline.deviceName" placeholder="设备名称" size="small" @change="changeName">
            <el-option v-for="item in devNameOptions" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input size="small" v-model.trim="formInline.input" :placeholder="`请输入${placeholder}`" @keyup.enter.native="onSubmit('ser')"></el-input>
        </el-col> -->
      <el-col :span="3">
        <!-- <el-button size="small" type="primary" @click="onSubmit('ser')">查询</el-button> -->
        <el-button
          size="small"
          type="primary"
          @click="advancedBtn"
          >{{ $t("public.Derive") }}</el-button
        >
      </el-col>
    </el-row>
    <!-- 点击选中只显示对应内容 -->
    <el-row :gutter="4" class="elRow" style="position: relative">
      <div
        style="
           display: inline-block;
           vertical-align: middle;
           position: absolute;
           right: 5px;
           top: -32px;
          cursor: pointer;
        "
      >
        <!-- 弹出框 -->
        <el-popover trigger="click" popper-class="LinkageTable">
          <el-checkbox-group @change="tableTitleTime" v-model="checkList">
            <el-checkbox
              v-for="(item, index) in tradeSelections"
              :key="index"
              :label="
                $t(`SecurityMonitor.SafetyEvent.Tablelinkage.${item.eng}`)
              "
              :checked="item.isChecked"
              :disabled="item.flag"
              @click.native="sss(item.eng, index, item.flag)"
            >
              {{
                $t(`SecurityMonitor.SafetyEvent.Tablelinkage.${item.name}`)
              }}</el-checkbox
            >
          </el-checkbox-group>
          <img
            slot="reference"
            src="@/images/showConfig.png"
            alt=""
            style="
              width: 20px;
              height: 20px;
            "
          />
        </el-popover>
      </div>
      <!-- 表格 -->
      <el-table
        size="mini"
        ref="myTable"
        :data="tableData"
        stripe
        @sort-change="sort"
        fit
        tooltip-effect="dark"
        class="tableClass"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        :default-sort="{ prop: 'createTime', order: 'ascending' }"
      >
        <template>
          <el-table-column
            v-if="$filter.nameFilter(formThead, tradeSelections)"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              width="120"
              v-for="(item, index) in formThead"
              :key="index"
              :label="item.name"
            >
              <template slot-scope="scope">
                {{ scope.row[item.eng] }}
              </template>
            </el-table-column>
          </el-table-column>
        </template>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('public.DeviceName')"
          prop="DEVIVENAME"
          v-if="tradeSelections[tradeSelections.length - 20].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.deviceName }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          fixed
          width="120"
          :label="$t('SecurityMonitor.SafetyEvent.EventName')"
          prop="EVENTNAME"
          v-if="tradeSelections[tradeSelections.length - 19].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.eventName }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('public.Behaviorlevel')"
          width="120"
          prop="LEVEL"
          v-if="tradeSelections[tradeSelections.length - 18].isChecked"
        >
          <template slot-scope="scope">
            <span
              style="color: #d9001b; cursor: pointer"
              v-if="scope.row.level == '3'"
              >{{ $t("SecurityMonitor.Sensitivebehavior.High") }}</span
            >
            <span
              style="color: #f59a23; cursor: pointer"
              v-if="scope.row.level == '2'"
              >{{ $t("SecurityMonitor.Sensitivebehavior.Middle") }}</span
            >
            <span
              style="color: #00cdcd; cursor: pointer"
              v-if="scope.row.level == '1'"
              >{{ $t("SecurityMonitor.Sensitivebehavior.Low") }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.ApplicationName')"
          prop="NAME"
          v-if="tradeSelections[tradeSelections.length - 17].isChecked"
        >
          <template slot-scope="scope">
            <!-- <template v-if="scope.row.eventName == 'longOffline' || scope.row.eventName == 'loginSecurity' || scope.row.eventName ==
            'systemIntegrity' || scope.row.eventName == 'netConnect' || scope.row.eventName == 'tpmReport'">
              /
            </template>
            <template v-else>{{ scope.row.name }} </template> -->
            <template>{{ scope.row.name }} </template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.ApplicationVersion')"
          prop="VERSION"
          v-if="tradeSelections[tradeSelections.length - 16].isChecked"
        >
          <template slot-scope="scope">
            <!-- <template v-if="scope.row.eventName == 'longOffline' || scope.row.eventName == 'loginSecurity' || scope.row.eventName == 'systemIntegrity'
            || scope.row.eventName == 'netConnect' || scope.row.eventName == 'tpmReport'">
              /
            </template>
            <template v-else>{{ scope.row.version }}</template> -->
            <template>{{ scope.row.version }}</template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.SystemVersion')"
          prop="OS_VERSION"
          v-if="tradeSelections[tradeSelections.length - 15].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.osVersion }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Position')"
          v-if="tradeSelections[tradeSelections.length - 14].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.location }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.LatitudeLongitude')"
          v-if="tradeSelections[tradeSelections.length - 13].isChecked"
        >
          <template slot-scope="scope">
            <span
              class="detaildesc"
              v-if="scope.row.longitude == '' || scope.row.latitude == ''"
            ></span>
            <span class="detaildesc" v-else
              >{{ scope.row.longitude }},{{ scope.row.latitude }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.InternetMethod')"
          prop="NET_TYPE"
          v-if="tradeSelections[tradeSelections.length - 12].isChecked"
        >
          <template slot-scope="scope">
            {{ $filter.surfInterType(scope.row.netType) }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.ExternalIP')"
          prop="OUT_IP"
          v-if="tradeSelections[tradeSelections.length - 11].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.outIp }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.IntranetIP')"
          prop="IP"
          v-if="tradeSelections[tradeSelections.length - 10].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.ip }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="WIFI-SSID"
          prop="WIFI_SSID"
          v-if="tradeSelections[tradeSelections.length - 9].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiSsid }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          label="WIFI-MAC"
          prop="WIFI_MAC"
          v-if="tradeSelections[tradeSelections.length - 8].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.wifiMac }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Username')"
          prop="LOGINNAME"
          v-if="tradeSelections[tradeSelections.length - 7].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.loginname }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Name')"
          prop="USERNAME"
          v-if="tradeSelections[tradeSelections.length - 6].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.username }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="120"
          :label="$t('public.Section')"
          prop="GROUPFULLNAME"
          v-if="tradeSelections[tradeSelections.length - 5].isChecked"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupfullname">{{
              row.groupfullname.substr(groupFullNameShowByIndex)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('public.GenerationTime')"
          prop="CREATETIME"
          v-if="tradeSelections[tradeSelections.length - 4].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.createtime }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('SecurityMonitor.SafetyEvent.BanTime')"
          prop="UPDATETIME"
          v-if="tradeSelections[tradeSelections.length - 3].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.updateTime }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="140"
          :label="$t('public.ReportingTime')"
          prop="TIME"
          v-if="tradeSelections[tradeSelections.length - 2].isChecked"
        >
          <template slot-scope="scope">
            {{ scope.row.time }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('public.Controls')">
          <template slot-scope="scope">
            <span
              size="small"
              @click="details(scope.row)"
              style="color: #d38a08; cursor: pointer"
              >{{ $t("public.Details") }}</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 详情弹框 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('public.Details')"
      :dialogVisible="outerVisible"
      :flagbtn="false"
      :num="'470px'"
    >
      <eventDetail :detailData="detailData"></eventDetail>
    </Eldialog>
  </div>
</template>
<script>
import selectInput from "@/components/selectInput";
import options from "@/utils/country-level3-data"; //三级城市数据
import { regionData } from "element-china-area-data"; //引入
import pagination from "@/components/pagination/page";
import eventDetail from "./components/eventDetail.vue";
import Eldialog from "@/components/elDialog/index.vue";
export default {
  components: { selectInput, Eldialog, pagination, eventDetail },

  data() {
    return {
      key: 1, //table  key
      flag: 1,
      props: { multiple: true },
      number: 0,
      number1: 0,
      cityarrs: [],
      checkList: [],
      tbodyData: [],
      seleShift: "deviceName", // 选中下拉框转译
      formCustom: {
        address: "", //省份城市
        DetailMap: "", //城市地址详细地址拼接
      },
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头

      detailData: [], //详情data
      formInline: {
        // 选择日期
        startTime: "",
        endTime: "",
        level: "",
        reason: "",
        deviceName: this.$t("public.DeviceName"),
        location: [],
        input: "",
      },
      cityOptions: options, // 城市数据
      placeholder: this.$t("public.DeviceName"),
      levelOptions: [
        { value: "", label: "行为级别" },
        { value: "3", label: "高" },
        { value: "2", label: "中" },
        { value: "1", label: "低" },
      ], //行为级别
      outerVisible: false,
      reasonOptions: [], //事件名称
      devNameOptions: [
        { value: "name", label: this.$t("public.ApplicationName") },
        { value: "version", label: this.$t("public.ApplicationVersion") },
        { value: "deviceName", label: this.$t("public.DeviceName") },
        { value: "loginname", label: this.$t("public.Username") },
        { value: "username", label: this.$t("public.Name") },
        { value: "groupfullname", label: this.$t("public.Section") },
        // { value: "osVersion", label: "系统版本" },
      ], //设备名称

      pickerOptions1: {},
      pickerOptions0: {},
      selectedOptions: [], //存放默认值
      tradeSelections: [
        { eng: "deviceName", name: "设备名称", isChecked: true, flag: true },
        { eng: "eventName", name: "事件名称", isChecked: true, flag: true },
        { eng: "level", name: "行为级别", isChecked: true, flag: false },
        { eng: "name", name: "应用名称", isChecked: true, flag: true },
        { eng: "version", name: "应用版本", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: true, flag: false },
        { eng: "location", name: "位置", isChecked: true, flag: false },
        {
          eng: "longitudeAndLatitude",
          name: "经纬度",
          isChecked: false,
          flag: false,
        },
        { eng: "netType", name: "上网方式", isChecked: false, flag: false },
        { eng: "outIp", name: "外网IP", isChecked: true, flag: false },
        { eng: "ip", name: "内网IP", isChecked: false, flag: false },
        { eng: "wifiSSID", name: "WIFI-SSID", isChecked: false, flag: false },
        { eng: "wifiMac", name: "WIFI-MAC", isChecked: false, flag: false },
        { eng: "loginname", name: "用户名", isChecked: true, flag: false },
        { eng: "username", name: "姓名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: true, flag: false },
        { eng: "createtime", name: "产生时间", isChecked: true, flag: false },
        { eng: "updateTime", name: "封禁时间", isChecked: false, flag: false },
        { eng: "time", name: "上报时间", isChecked: true, flag: true },
        { eng: "decide", name: "操作", isChecked: true, flag: true },
      ], // isChecked：true选中(默认)false不选中    flag：true是禁用(不可编辑)false不禁用(可编辑)
      //   分页
      currentPage: 1, //当前页
      pageSize: 10, //每页显示条数
      total: 0,
      orderColume: "createtime", //排序字段
      orderRule: "desc", //排序规则ASC、DESC
      tableData: [], //列表data
      newEventData: [
        {
          id: "",
          name: this.$t("SecurityMonitor.SafetyEvent.EventName"),
          type: "",
        },
      ],
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },

  created() {
    if (this.$route.query.cityName) {
      let cityArray = this.$route.query.cityName.split(",");
      let options = [
        this.$t("SecurityMonitor.Sensitivebehavior.BeiJing"),
        this.$t("SecurityMonitor.Sensitivebehavior.TianJin"),
        this.$t("SecurityMonitor.Sensitivebehavior.ShangHai"),
        this.$t("SecurityMonitor.Sensitivebehavior.ChongQing"),
      ];
      options.forEach((i, index) => {
        if (cityArray.length == 1) {
          if (cityArray[0] == i) {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, item.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          } else {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, items.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          }
        } else {
          if (cityArray[0] == i) {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    if (itemss.value == cityArray[1]) {
                      this.cityarrs.push([
                        item.value,
                        item.value,
                        itemss.value,
                      ]);
                    }
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          } else {
            this.cityOptions.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  if (items.value == cityArray[1]) {
                    items.children.forEach((itemss, indexss) => {
                      this.cityarrs.push([
                        item.value,
                        items.value,
                        itemss.value,
                      ]);
                    });
                  }
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          }
        }
      });
    }
  },

  //取token
  beforeMount() {
    const token = sessionStorage.getItem("userName");
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    if (this.$route.query.flag == false) {
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.startTime = this.$route.query.startTime;

      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == true) {
      this.formInline.startTime = this.$route.query.startTime.split(" ")[0];

      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == 2) {
      if (
        this.$route.query.startTime == "00" ||
        this.$route.query.startTime == "01" ||
        this.$route.query.startTime == "02" ||
        this.$route.query.startTime == "03" ||
        this.$route.query.startTime == "04" ||
        this.$route.query.startTime == "05" ||
        this.$route.query.startTime == "06" ||
        this.$route.query.startTime == "07" ||
        this.$route.query.startTime == "08" ||
        this.$route.query.startTime == "09" ||
        this.$route.query.startTime == "10" ||
        this.$route.query.startTime == "11" ||
        this.$route.query.startTime == "12" ||
        this.$route.query.startTime == "13" ||
        this.$route.query.startTime == "14" ||
        this.$route.query.startTime == "15" ||
        this.$route.query.startTime == "16" ||
        this.$route.query.startTime == "17" ||
        this.$route.query.startTime == "18" ||
        this.$route.query.startTime == "19" ||
        this.$route.query.startTime == "20" ||
        this.$route.query.startTime == "21" ||
        this.$route.query.startTime == "22" ||
        this.$route.query.startTime == "23" ||
        this.$route.query.startTime == "24"
      ) {
        this.formInline.startTime = this.$common
          .UTCformat(this.getdatatime())
          .split(" ")[0];
        this.formInline.endTime = this.$common
          .UTCformat(this.getdatatime())
          .split(" ")[0];
        this.onSubmit(); //再次调用查询接口
      } else {
        this.formInline.startTime = this.$route.query.startTime;
        this.formInline.endTime = this.$route.query.startTime;
        this.onSubmit(); //再次调用查询接口
      }
      // 当天
    } else if (this.$route.query.flag == 3) {
      //违规行为分布图表
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.startTime = this.$route.query.startTime;

      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == 4) {
      //违规行为分布图表
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.startTime = this.$route.query.starttime;

      this.formInline.endTime = this.$common.getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.cityName && this.$route.query.starttime) {
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.inpSearch();
      this.onSubmit(); //再次调用查询接口
    } else {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || "-";
        var nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + n);
        var year = nowDate.getFullYear();
        var month = nowDate.getMonth() + 1;
        var day = nowDate.getDate();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;
        return year + symbol + month + symbol + day;
      }; //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };
      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      } else if (this.$route.query.card == true) {
        // debugger
        this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
        this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
        console.log(this.formInline.startTime, this.formInline.endTime, "card");
      } else {
        this.formInline.startTime = this.$common.getAnyMonth("-", -1);
        this.formInline.endTime = this.$common.getDate();
      }
      this.getDataList();
    }
    // this.getDataList()
    this.getDeviceName();
  },

  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  },

  methods: {
    selectSearchChange() {},
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    inpSearch() {
      this.formInline.deviceName = this.$t("public.ApplicationName");
      this.placeholder = this.$t("public.ApplicationName");
      // this.formInline.deviceName = this.seleShift;
      console.log(this.formInline.deviceName, "---");
      this.seleShift = "name";

      this.onSubmit();
    },

    // 排序@sort-change="sort"
    sort(column) {
      if (column.order === "ascending") {
        this.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.orderRule = "DESC";
      }
      this.orderColume = column.prop;
      this.onSubmit(); //再次调用查询接口
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    Day() {
      this.formInline.startTime = this.$route.query.dataDay.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.onSubmit(); //
    },
    Mounth() {
      this.formInline.startTime = this.$route.query.dataMounth.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      this.onSubmit();
    },
    card() {
      this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
      this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
      console.log(this.formInline.startTime, this.formInline.endTime, "card");
      this.onSubmit();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDataList(this.selectVal);
    },
    changeDate() {
      let start = new Date(this.formInline.startTime).getTime();
      let end = new Date(this.formInline.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },

    // 列表
    async getDataList(val) {
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }

      this.changeDate();
      if (
        this.formInline.reason ==
        this.$t("SecurityMonitor.SafetyEvent.EventName")
      ) {
        this.formInline.reason = "";
      }

      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        reason: "",
        level: this.formInline.level,
        location: location,
        eventName: this.formInline.reason,
        deviceName: "",
         
        customKey: val ? val.deviceName : "",
        customVal: val ? val.InputText : "",
      };
      console.log(parma, "参");
      const res = await this.$axios.post(
        "/httpServe/appEvent/getDataInfo",
        parma,
        true
      );
      console.log(res, "列表");
      this.tableData = res.data.content;
      this.total = res.data.total;
      // 对于为空的字段，显示N/A
      for (var i = 0; i < this.tableData.length; i++) {
        var obj2 = this.tableData[i];
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
      }
    },
    // 获取事件名称
    async getDeviceName() {
      var parma = { type: "event" };
      const res = await this.$axios.post(
        "/httpServe/monitorExEventRule/selectNameList",
        parma,
        true
      );
      let eventOptions = res.data;
      this.reasonOptions = [...this.newEventData, ...eventOptions];
      //  console.log(this.reasonOptions, "事件名称");
    },
    changeName(item) {
      this.formInline.deviceName = item;
      this.placeholder = item;
      this.devNameOptions.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
        }
      });
    },
    //查询
    async onSubmit(val) {
      this.selectVal = val;
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      } else if (this.$route.query.card == true) {
        // debugger
        this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
        this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
        console.log(this.formInline.startTime, this.formInline.endTime, "card");
      } else {
        this.changeDate();
      }
      if (
        this.formInline.reason ==
        this.$t("SecurityMonitor.SafetyEvent.EventName")
      ) {
        this.formInline.reason = "";
      }
      this.currentPage = 1;
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        reason: "",
        level: this.formInline.level,
        deviceName: "",
        location: location,
        eventName: this.formInline.reason,
         
        customKey: val.deviceName,
        customVal: val.InputText,
      };
      console.log(parma, "查询参数");
      const res = await this.$axios.post(
        "/httpServe/appEvent/getDataInfo",
        parma,
        true
      );
      // console.log(res);
      this.tableData = res.data.content;
      this.total = res.data.total;
      // 对于为空的字段，显示N/A
      for (var i = 0; i < this.tableData.length; i++) {
        var obj2 = this.tableData[i];
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
      }
    },

    //导出
    async advancedBtn() {
      let arrOut = [];
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != "操作") {
          arrOut.push(item.eng); //去重
          this.tradeSelections1 = [...new Set(arrOut)];
          //           this.tradeSelections1.unshift("name", "battery")
        }
      });
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMzNzU1NjQsInVzZXJJZCI6MSwiaWF0IjoxNjMyNjU1NTY0fQ.OtDA-uF3NfP8xqj902kqxPu54UPgs8SYVY1nBTl1Ifnvoxw_-MPmcN_DKeYDQXHZfEizztITgMABrO0tBmvshA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );

      let groupId = JSON.parse(userString).userId;

      this.userId = groupId;

      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }
      if (this.$route.query.card == true) {
        // debugger
        this.formInline.startTime = this.$route.query.starttime.split(" ")[0];
        this.formInline.endTime = this.$route.query.dataDay.split(" ")[0];
        console.log(this.formInline.startTime, this.formInline.endTime, "card");
      }
      if (
        this.formInline.reason ==
        this.$t("SecurityMonitor.SafetyEvent.EventName")
      ) {
        this.formInline.reason = "";
      }
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.total,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        // reason: "",
        level: this.formInline.level,
        deviceName: "",
        location: location,
        eventName: this.formInline.reason,
         
        customKey: this.selectVal.deviceName
          ? this.selectVal.deviceName
          : this.seleShift,
        customVal: this.selectVal.InputText
          ? this.selectVal.InputText
          : this.formInline.input,
        exportField: this.tradeSelections1,
      };
      const res = await this.$axios.postExport(
        "/httpExport/appEvent/exportExcel",
        parma,
        true,
        this.userId
      ); // console.log(res);
      const link = document.createElement("a");

      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportEvent_" + Date.parse(new Date()) + ".xls";

      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 详情
    details(info) {
      this.outerVisible = true;
      this.detailData = info;
      this.detailData["decideStr"] = eval(this.detailData.decide);
    },
    //配置项监听事件
    tableTitleTime(event) {},
    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "devicename" ||
          index == "eventName" ||
          index == "level" ||
          index == "name" ||
          index == "osVersion" ||
          index == "version" ||
          index == "location" ||
          index == "longitudeAndLatitude" ||
          index == "netType" ||
          index == "outIp" ||
          index == "ip" ||
          index == "wifiSSID" ||
          index == "wifiMac" ||
          index == "username" ||
          index == "loginName" ||
          index == "loginname" ||
          index == "groupFullName" ||
          index == "groupfullname" ||
          index == "updateTime" ||
          index == "time" ||
          index == "createtime"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.SensitiveMonitor {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}

.location {
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
@-moz-document url-prefix() {
  .location {
    &::v-deep {
      & ::placeholder {
        color: #606266;
        opacity: 1 !important; /*火狐默认有opacity:0.4; */
      }
    }
  }
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}

.detailContent .boxBot .group .detaildesc[data-v-74a6706d] {
  width: 78%;
  display: inline-table;
}

.detailContent .boxBot .group .detailLabel[data-v-8f7c1760] {
  width: 13%;
}

.detailContent .boxBot .group .detailLabel[data-v-70181544] {
  width: 13%;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
.placeInput {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-cascader__tags .el-cascader__search-input::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep
    .el-cascader__tags
    .el-cascader__search-input::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-cascader__tags .el-cascader__search-input::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-cascader__tags .el-cascader__search-input:-ms-input-placeholder {
    color: #606266 !important;
  }
}
</style>